import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
function Mentions() {
    return (
        <Layout>
            <Seo title="Mentions Légales" />
            <h2 style={titleStyle}>Mentions Légales</h2>
            <div style={mainStyle}>
                <p style={{ color: "white" }}>Données météos : API Openweathermap </p>
                <p style={{ color: "white" }}>Données films et séries : API TMDB The Movie Database </p>
                <p style={{ color: "white" }}>Carte : Leaflet et OpenStreetMap</p>
            </div>
        </Layout>
    )
}

const titleStyle = { textAlign: "center", color: "rgb(235,235,235)" }
const mainStyle = { display: "flex", flexDirection: "column", width: "98vw", flexWrap: "wrap", justifyContent: "center", alignItems: "center", marginLeft:"1vw", marginRight:"1vw" }
export default Mentions